.btn-custom-icon {
  width: 50px;
}
.btn-custom-text {
  width: 120px;
}

.btn-custom-mixed {
  width: 130px;
}

.form-check-input {
  width: 15px;
  height: 15px;
}

.btn:not(.btn-link) {
  height: 40px;
}

div.app-sidebar-toggle {
  box-shadow: none !important;
}

button.btn.btn-primary.minimized-add-btn {
  background-image: url('../../../../../../public/media/company-specific/pokupka/btn_small.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  color: #fff;
}

.minimized-add-btn {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
@include media-breakpoint-down(md) {
  .card-footer {
    position: relative;
  }
  .minimized-add-btn.minimized-floating {
    z-index: 100;
  }
  .minimized-add-btn {
    position: fixed;
    top: 75%;
    right: 10%;
  }
}

@include media-breakpoint-up(md) {
  .minimized-add-btn.minimized-floating {
    display: none !important;
  }
}

@include media-breakpoint-only(md) {
  button.btn.btn-primary.minimized-add-btn {
    width: 40px;
    height: 40px;
  }
}

@include media-breakpoint-up(lg) {
  .profile-address-wrapper {
    position: relative;
  }

  [data-kt-app-sidebar-minimize='on'] .minimized-add-btn {
    display: none !important;
  }
  [data-kt-app-sidebar-minimize='on'] .minimized-add-btn.minimized-floating {
    position: fixed !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    top: 120px;
    left: 70px;
  }
}
