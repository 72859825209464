@include media-breakpoint-down(md) {
  .app-footer {
    position: relative;
  }

  .footer-link {
    font-size: 16px;
  }
}

@include media-breakpoint-up(md) {
  .footer-link {
    font-size: 12px;
  }
}
