.AuthLayout_bg {
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.Login_form-button {
  background: linear-gradient(93deg, #045487 2.67%, #ce2586 97.7%);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  font-size: 20px;
  font-weight: 700;
  color: white;
  height: 50px !important;
}
.form-check-input.input_general,
.form-control.form-control-lg.input_general,
.form-control.form-control-lg.form-control-solid.input_general {
  padding-top: 13px;
  padding-bottom: 13px;
  border-radius: 0;
  border-left: 1px $grey-light solid !important;
  background-color: transparent !important;
}
.form-check-input.input-border,
.input-border {
  border: 1px #bebebe solid !important;
}

.social-button {
  border-radius: 0% !important;
  border: 1px transparent solid !important;
}
.social-google {
  color: #bf1d1d !important;

  border-color: #bf1d1d !important;
}
.social-facebook {
  color: #045487 !important;

  border-color: #045487 !important;
}
.dropdown-toggle {
  padding: 0 !important;
}
.dropdown-toggle::after {
  display: none !important;
}

@include media-breakpoint-down(md) {
  //   .AuthLayout {
  //     position: relative;
  //   }
  .AuthLayout {
    background-image: url('../../../../../../public/media/company-specific/pokupka/bw-bg-mob.svg');
    background-size: cover;
    background-repeat: no-repeat;

    background-position: top right;
  }
}
@include media-breakpoint-up(md) {
  .AuthLayout {
    background-color: #fff;
    position: relative;
    padding-left: 220px;
  }
  .AuthLayout_bg {
    width: 220px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-image: url('../../../../../../public/media/company-specific/pokupka/name.svg'),
      url('../../../../../../public/media/company-specific/pokupka/bg.svg');
    background-position: bottom 20px right 0px, top left 45%;
  }
}
@include media-breakpoint-up(xl) {
  .AuthLayout {
    background-color: #fff;
    position: relative;
    padding-left: 650px;
  }
  .AuthLayout_bg {
    width: 650px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-image: url('../../../../../../public/media/company-specific/pokupka/name.svg'),
      url('../../../../../../public/media/company-specific/pokupka/bg.svg');
    background-position: bottom 20px right 0px, top left;
  }
}
