.profile-photo-container {
  height: 180px;
}
@include media-breakpoint-up(md) {
  .profile-photo-container {
    height: 230px;
  }

  .profile-basics-container {
    padding: 20px;
  }

  .profile-address-container {
    padding: 40px 20px 30px 20px;
  }

  .card.profile-form {
    box-shadow: none;
    height: 100% !important;
    * .card-body {
      height: 100% !important;
    }
  }
}
@include media-breakpoint-up(lg) {
  .profile-basics-container {
    padding: 40px;
  }
  .profile-address-container {
    padding: 80px 40px 20px 40px;
  }
}
