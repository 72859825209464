.table-orders.table-half-rounded tr:not(.h-0),
.table-orders.table-half-rounded td:not(.h-0):not(.subcell) {
  height: 70px;
}

.table-orders.table-half-rounded td.actions-td {
  padding-left: 0 !important;
  padding-bottom: 4px !important;
}
.table-orders.table-half-rounded tr.details > td {
  padding-left: 10px;
  padding-right: 10px;
}

.table-orders.table-half-rounded th {
  padding-left: 22px !important;
}
.table-orders.table-half-rounded tr.h-0,
.table-orders.table-half-rounded th.h-0 {
  height: 0;
  padding: 5px !important;
}
.table-orders.table-half-rounded,
.table-prods.table-half-rounded {
  border-spacing: 0;
  border-collapse: separate;

  & td:first-child:not(.details > td) {
    border-right: none !important;
  }
  & td:last-child:not(.details > td) {
    border-left: none !important;
  }
  & td:not(:first-child):not(:last-child):not(.details > td) {
    border-right: none !important;
    border-left: none !important;
  }

  & td,
  .action-container {
    position: relative;
  }
  .action-container:not(:first-child):before {
    position: absolute;
    top: 0.5px;
    left: 0;
    content: '';
    height: 96%;
    width: 0;
    border-right: 1px solid $grey-light;
  }
}
.table-orders tr.details td .row > div {
  position: relative;
}

.table-orders.table-half-rounded td.subcell {
  white-space: nowrap !important;
  line-height: 1;
  max-height: 25px !important;
}

th.parcel-ttn {
  display: none;
}

.order-log .log-date {
  width: 40%;
}

.log-point {
  position: relative;

  &:before {
    position: absolute;
    top: 15%;
    left: 75%;
    transform: translateX(-40%);
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: $grey-text;
  }
}
.order-log:first-child .log-point:before,
.order-log:last-child .log-point:before {
  top: 0;
  width: 13px;
  height: 13px;
}
.order-log:last-child .log-point {
  border: none !important;
  &:before {
    background-color: $accent;
  }
}
.order-log .log-text {
  width: 60%;
}

.table.table-orders.table-half-rounded.buyouts-table th.selection-th.d-none {
  display: table-cell !important;
}

@include media-breakpoint-down(md) {
  .table-orders.table-half-rounded tr:not(.details) > td:last-child,
  .table-prods.table-half-rounded tr:not(.details) > td:last-child {
    border-top-right-radius: $border-radius !important;
  }
  .table-orders.table-half-rounded tr:not(.details):not(.open) > td:last-child,
  .table-prods.table-half-rounded tr:not(.details):not(.open) > td:last-child {
    border-bottom-right-radius: $border-radius !important;
  }

  .table-orders.table-half-rounded td:not(tr.details > td):first-child {
    padding: 20px 0 20px 10px;
  }
  .table-prods.table-half-rounded td:not(tr.details > td):first-child {
    padding: 10px 0 10px 10px;
  }

  .table-prods.table-half-rounded {
    & td:not(:first-child):before {
      position: absolute;
      top: 10px;
      left: 0;
      content: '';
      height: 60%;
      width: 0;
      border-right: 1px solid $grey-light;
    }
  }
}

@include media-breakpoint-up(md) {
  .table-orders.table-half-rounded tr:not(.h-0),
  .table-orders.table-half-rounded td:not(.h-0):not(.subcell) {
    height: 50px;
  }
  .table-orders.table-half-rounded {
    & td:not(:first-child):not(.subcell):before {
      position: absolute;
      top: 10px;
      left: 0;
      content: '';
      height: 60%;
      width: 0;
      border-right: 1px solid $grey-light;
    }

    & tr.details td .row:not(.subrow) > div:not(:last-child):after {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      height: 100%;
      width: 0;
      border-right: 1px solid $grey-light;
    }
  }

  .table-orders.table-half-rounded td:not(tr.details > td) {
    padding: 5px 20px;
  }

  .table-orders.table-half-rounded .action-container {
    padding: 5px 15px !important;
  }

  .table-orders.table-half-rounded tr.details > td {
    padding-left: 20px;
    padding-right: 20px;
  }

  .table-prods.table-half-rounded td:not(tr.details > td):first-child {
    padding: 15px 0 20px 15px;
  }
}

@include media-breakpoint-only(md) {
  .table-orders.table-half-rounded {
    & tr.details td .row:not(.subrow) > div.order-md-2:after,
    tr.details td .row:not(.subrow) > div.order-md-6:after {
      position: absolute;
      top: 0;
      right: 0;
      content: '';
      height: 100%;
      width: 0;
      border: none !important;
    }
  }
}

@include media-breakpoint-up(lg) {
  .table.table-orders.table-half-rounded.buyouts-table th.logo-th.d-none {
    display: table-cell !important;
  }

  .parcels-table th.parcel-ttn {
    display: table-cell;
  }
}
