#scrolltop {
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  background: #5d78ff;
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
  opacity: 0;
  transition: all 0.3s;
  border-radius: 4px;
  color: #ffffff;
}
.scrolltop--on #scrolltop {
  opacity: 0.3;
  animation: scrolltop-show-animation 0.4s ease-out 1;
  display: flex;
}
#scrolltop:hover {
  transition: all 0.3s;
  opacity: 1 !important;
}
#footer,
#footer a {
  color: #c6c5d3;
  font-size: 12px;
  line-height: 27px;
}
#footer a {
  white-space: nowrap;
  text-decoration: underline;
}
#footer a.social {
  font-size: 22px;
  line-height: 1;
}
.btn.btn-normal {
  border-radius: 30px !important;
  background-image: url('../../../../../../public/media/company-specific/pokupka/btn.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-weight: bold;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  border: none;
}
.btn.btn-clean:hover,
.btn.btn-normal:hover,
.btn.Login_form-button:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25) !important;
}
.btn.btn-normal:active,
.btn.Login_form-button:active {
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.btn.btn-clean {
  border-radius: 30px !important;
  color: #cf2787;
  background: #fff;
  padding-left: 6px;
  padding-right: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.btn.btn-clean:not(.with-icon) {
  color: #8592a6;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
}
.btn.with-icon {
  display: inline-flex;
  align-items: center;
}
.btn.btn-clean > .icon {
  display: inline-block;
  color: #fff;
  vertical-align: middle;
  background-image: url('../../../../../../public/media/company-specific/pokupka/btn_small.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}
.btn.btn-clean > .text {
  padding: 0 10px;
}
.btn.btn-clean > .icon > svg {
  vertical-align: -0.4em;
}

.btn-group.slider {
  background: #f8f8f8;
  border-radius: 30px;
}
.btn-group.slider > .btn.btn-clean {
  box-shadow: none;
  background: transparent;
}
.btn-group.slider > .btn.btn-clean.hover,
.btn-group.slider > .btn.btn-clean.active,
.btn-group > .btn.btn-clean.slider.hover,
.btn-group > .btn.btn-clean.slider.active {
  background: #ffffff;
  color: #cf2787;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.btn-group.filter.slider > .btn.btn-clean,
.btn-group > .btn.btn-clean.filter.slider {
  color: #045487;
}
.btn-group.filter.slider > .btn.btn-clean > .badge,
.btn-group > .btn.btn-clean.filter.slider > .badge {
  background: transparent;
  color: #045487;
  border: 1px solid #045487;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.btn-group.filter.slider > .btn.btn-clean.hover,
.btn-group > .btn.btn-clean.filter.slider.hover {
  background: #045487;
  color: #ffffff;
}
.btn-group.filter.slider > .btn.btn-clean.hover > .badge,
.btn-group > .btn.btn-clean.filter.slider.hover > .badge {
  color: #ffffff;
  border-color: #ffffff;
}

.btn.btn-calendar {
  background: none !important;
  border: none !important;
  color: #c6c5d3 !important;
  text-decoration: none !important;
}
.btn.btn-calendar svg {
  color: #045487 !important;
}

.card {
  color: #8592a6;
  border: 1px solid #c6c5d3;
  border-radius: 5px;
}
.card .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5L3.86957 9L12 1' stroke='%23CF2787' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.menu__link-text {
  padding-left: 5px;
}

.header__topbar {
  display: flex;
  margin-left: 3rem;
}
.header__topbar .navbar-nav .nav-item {
  display: flex;
  align-items: center;
}
.header__topbar .nav-link {
  padding: 0 0.5rem;
  color: #045487;
  white-space: nowrap;
}
.dropdown-menu {
  position: absolute !important;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.dropdown-menu .dropdown-item {
  padding: 10px 20px;
}
.header__topbar .navbar-nav .dropdown-menu .dropdown-item {
  color: #8592a6;
}
.header__topbar .navbar-nav .dropdown-menu .dropdown-item svg {
  margin-right: 6px;
}
.header__topbar .navbar-nav .dropdown-menu .dropdown-item:active {
  color: #fff;
}
.header__topbar .navbar-nav .dropdown-menu:after,
.header__topbar .navbar-nav .dropdown-menu:before {
  content: ' ';
  position: absolute;
  top: -10px;
  right: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgba(0, 0, 0, 0.2) transparent;
}
.header__topbar .navbar-nav .dropdown-menu:after {
  border-color: transparent transparent #fff transparent;
}
.header__topbar .navbar-nav .header-search.dropdown-menu {
  border-radius: 30px;
  min-width: 300px;
  transform: translateX(100px);
}
.header__topbar .navbar-nav .header-search.dropdown-menu:before,
.header__topbar .navbar-nav .header-search.dropdown-menu:after {
  right: 110px;
}
.header-search .input-group {
  position: relative;
  background: #f8f8f8;
  border: none;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
}
.header-search .input-group,
.header-search .btn,
.header-search .input-group-text {
  border-radius: 30px;
}
.header-search .input-group-text,
.header-search .btn,
.header-search input {
  height: 40px;
  border: none;
  background: transparent !important;
}
.header-search input {
  padding-left: 0;
  padding-right: 0;
  height: 40px;
}
.header-search .input-group-append {
  min-width: 35px;
}

.the_add {
  position: absolute;
  z-index: 1003;
}
.the_add button {
  white-space: nowrap;
  max-width: 150px;
}
.fab .dropdown-item:active,
.the_add .dropdown-item:active {
  color: #fff;
}

.aside > .scrollbar-container.ps.ps--active-y {
  overflow-x: visible !important;
}
.aside--minimize .aside {
  width: 56px !important;
}
.aside--minimize .aside-menu .menu__nav > .menu__item > .menu__link .menu__link-text,
.aside--minimize .aside-menu .menu__nav > .menu__item > .menu__link .menu__ver-arrow,
.aside--minimize .aside .btn-text {
  display: none;
}
.aside-toggler span {
  display: none;
}
.aside-toggler:not(.aside-toggler--active) span:first-child {
  display: inline-block;
}
.aside-toggler.aside-toggler--active span:last-child {
  display: inline-block;
}

.header-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1010;
}
.aside {
  width: 225px !important;
  max-width: 100%;
  background: #ffffff;
}
.aside-menu-wrapper {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.aside-footer a.social {
  color: #c6c5d3;
  justify-content: center;
}
.aside--minimize .aside-footer {
  -ms-flex-direction: column;
  flex-direction: column;
}
.aside-toggler {
  display: inline-block;
  padding: 0;
  border: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
  width: 30px;
}
hr {
  border-top: 1px solid #c6c5d3;
}
.aside-menu .menu__nav {
  margin: 0;
  list-style: none;
  padding: 5px 0;
}
.aside-menu .menu__nav > .menu__item {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  float: none;
  padding: 0;
}
.aside-menu .menu__nav > .menu__item > .menu__link {
  padding: 9px 25px;
}
.aside-menu .menu__nav > .menu__item .menu__submenu .menu__item > .menu__link,
.aside-menu .menu__nav > .menu__item > .menu__heading,
.aside-menu .menu__nav > .menu__item > .menu__link {
  cursor: pointer;
  min-height: 40px;
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  margin: 0;
  padding: 0;
  text-decoration: none;
  position: relative;
  outline: none;
}
.aside-menu .menu__nav .menu__link-icon {
  display: flex;
  flex: 0 0 56px;
  background-repeat: no-repeat;
  background-position: 65% center;
  height: 40px;
}
.aside--minimize .aside-menu .menu__nav .menu__link-icon {
  flex: 0 0 50px;
}
.aside-menu .menu__nav .menu__item > .menu__heading .menu__link-text,
.aside-menu .menu__nav .menu__item > .menu__link .menu__link-text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0;
  font-size: 16px;
  line-height: 19px;
  color: #8592a6;
}
.aside-menu .menu__nav .menu__item {
  background-position: left top;
  background-repeat: no-repeat;
}
.aside-menu .menu__nav .menu__item:not(.menu__item--open) + .menu__item {
  margin-top: 16px;
}
.aside-menu .menu__nav > .menu__item.pos1 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame1.svg');
}
.aside-menu .menu__nav > .menu__item.pos1:hover,
.aside-menu .menu__nav > .menu__item.pos1.menu__item--active {
  background-color: rgba(162, 56, 141, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos1:active {
  background-color: rgba(162, 56, 141, 0.2);
}
.aside-menu .menu__nav > .menu__item.pos1.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos1.menu__item--active > .menu__link .menu__link-text {
  color: #a2388d;
}
.aside-menu .menu__nav > .menu__item.pos1 .menu__link-icon.icon1 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon1.svg');
}
.aside-menu .menu__nav > .menu__item.pos2 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame2.svg');
}
.aside-menu .menu__nav > .menu__item.pos2:hover,
.aside-menu .menu__nav > .menu__item.pos2.menu__item--active {
  background-color: rgba(206, 37, 134, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos2:active {
  background-color: rgba(206, 37, 134, 0.2);
}
.text-return,
.aside-menu .menu__nav > .menu__item.pos2.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos2.menu__item--active > .menu__link .menu__link-text {
  color: #ce2586;
}
.aside-menu .menu__nav > .menu__item.pos2 .menu__link-icon.icon2 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon2.svg');
}
.aside-menu .menu__nav > .menu__item.pos3 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame3.svg');
}
.aside-menu .menu__nav > .menu__item.pos3:hover,
.aside-menu .menu__nav > .menu__item.pos3.menu__item--here,
.aside-menu .menu__nav > .menu__item.pos3.menu__item--active {
  background-color: rgba(239, 126, 92, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos3:active {
  background-color: rgba(239, 126, 92, 0.2);
}
.aside-menu .menu__nav > .menu__item.pos3.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos3.menu__item--here > .menu__link .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos3.menu__item--active > .menu__link .menu__link-text {
  color: #ef7e5c;
}
.aside-menu .menu__nav > .menu__item.pos3 .menu__link-icon.icon3 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon3.svg');
}
.aside-menu .menu__nav > .menu__item.pos4 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame4.svg');
}
.aside-menu .menu__nav > .menu__item.pos4:hover,
.aside-menu .menu__nav > .menu__item.pos4.menu__item--active {
  background-color: rgba(251, 210, 32, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos4:active {
  background-color: rgba(251, 210, 32, 0.2);
}
.aside-menu .menu__nav > .menu__item.pos4.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos4.menu__item--active > .menu__link .menu__link-text {
  color: #fbf220;
}
.aside-menu .menu__nav > .menu__item.pos4 .menu__link-icon.icon4 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon4.svg');
}
.aside-menu .menu__nav > .menu__item.pos5 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame5.svg');
}
.aside-menu .menu__nav > .menu__item.pos5:hover,
.aside-menu .menu__nav > .menu__item.pos5.menu__item--active {
  background-color: rgba(201, 203, 39, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos5:active {
  background-color: rgba(201, 203, 39, 0.2);
}
.aside-menu .menu__nav > .menu__item.pos5.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos5.menu__item--active > .menu__link .menu__link-text {
  color: #c9cb27;
}
.aside-menu .menu__nav > .menu__item.pos5 .menu__link-icon.icon5 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon5.svg');
}
.aside-menu .menu__nav > .menu__item.pos6 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame6.svg');
}
.aside-menu .menu__nav > .menu__item.pos6:hover,
.aside-menu .menu__nav > .menu__item.pos6.menu__item--active {
  background-color: rgba(136, 192, 96, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos6:active {
  background-color: rgba(136, 192, 96, 0.2);
}
.aside-menu .menu__nav > .menu__item.pos6.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos6.menu__item--active > .menu__link .menu__link-text {
  color: #88c060;
}
.aside-menu .menu__nav > .menu__item.pos6 .menu__link-icon.icon6 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon6.svg');
}
.aside-menu .menu__nav > .menu__item.pos7 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame7.svg');
}
.aside-menu .menu__nav > .menu__item.pos7:hover,
.aside-menu .menu__nav > .menu__item.pos7.menu__item--active {
  background-color: rgba(3, 134, 135, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos7:active {
  background-color: rgba(3, 134, 135, 0.2);
}
.aside-menu .menu__nav > .menu__item.pos7.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos7.menu__item--active > .menu__link .menu__link-text {
  color: #038687;
}
.aside-menu .menu__nav > .menu__item.pos7 .menu__link-icon.icon7 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon7.svg');
}
.aside-menu .menu__nav > .menu__item.pos8 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame8.svg');
}
.aside-menu .menu__nav > .menu__item.pos8:hover,
.aside-menu .menu__nav > .menu__item.pos8.menu__item--active {
  background-color: rgba(8, 122, 123, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos8:active {
  background-color: rgba(8, 122, 123, 0.2);
}
.aside-menu .menu__nav > .menu__item.pos8.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos8.menu__item--active > .menu__link .menu__link-text {
  color: #087a7b;
}
.aside-menu .menu__nav > .menu__item.pos8 .menu__link-icon.icon8 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon8.svg');
}
.aside-menu .menu__nav > .menu__item.pos9 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame9.svg');
}
.aside-menu .menu__nav > .menu__item.pos9:hover,
.aside-menu .menu__nav > .menu__item.pos9.menu__item--active {
  background-color: rgba(40, 108, 180, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos9:active {
  background-color: rgba(40, 108, 180, 0.2);
}
.text-payment,
.aside-menu .menu__nav > .menu__item.pos9.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos9.menu__item--active > .menu__link .menu__link-text {
  color: #286cb4;
}
.aside-menu .menu__nav > .menu__item.pos9 .menu__link-icon.icon9 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon9.svg');
}
.aside-menu .menu__nav > .menu__item.pos10 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/frames/frame10.svg');
}
.aside-menu .menu__nav > .menu__item.pos10:hover,
.aside-menu .menu__nav > .menu__item.pos10.menu__item--active {
  background-color: rgba(64, 61, 144, 0.1);
}
.aside-menu .menu__nav > .menu__item.pos10:active {
  background-color: rgba(64, 61, 144, 0.2);
}
.aside-menu .menu__nav > .menu__item.pos10.menu__item--active > .menu__heading .menu__link-text,
.aside-menu .menu__nav > .menu__item.pos10.menu__item--active > .menu__link .menu__link-text {
  color: #403d90;
}
.aside-menu .menu__nav > .menu__item .menu__link-icon.icon10 {
  background-image: url('../../../../../../public/media/company-specific/pokupka/sidebar-icons/icon10.svg');
}

.menu__link-badge {
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  text-align: center;
}
.menu__link-badge .badge {
  font-size: 12px;
  line-height: 14px;
  border: 1px solid #cf2787;
  color: #cf2787;
  border-radius: 14px;
  padding: 0.15em 0.5em;
}
.aside--minimize .menu__link-badge {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0;
}
.aside--minimize .menu__link-badge .badge {
  font-size: 7px;
  line-height: 9px;
}

.menu__arrow {
  width: 40px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
}
.menu__ver-arrow {
  color: #bfbfbf;
  flex: 0 0 12px;
  margin-right: 0;
  font-size: 0.7rem;
  height: 40px;
  display: flex;
  line-height: 0;
}
.menu__item.menu__item--submenu.menu__item--open > .menu__link > .menu__arrow > .menu__ver-arrow {
  transform: rotateZ(180deg);
}
.aside-menu .menu__nav .menu__inner,
.aside-menu .menu__nav .menu__submenu {
  display: none;
  float: none;
  margin: 0;
  padding: 0;
}
.aside-menu .menu__nav .menu__item.menu__item--open > .menu__submenu,
.aside-menu .menu__nav .menu__item.menu__item--open > .menu__inner {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.aside-menu .menu__nav > .menu__item .menu__submenu .menu__subnav {
  margin: 0;
  background: #fff;
  padding: 16px 0;
  box-shadow: inset 0 14px 10px -10px rgba(0, 0, 0, 0.1),
    inset 0 -10px 10px -10px rgba(0, 0, 0, 0.1);
}
.aside-menu .menu__nav .menu__subnav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  list-style: none !important;
}
.aside-menu .menu__nav > .menu__item .menu__submenu .menu__item {
  margin: 0;
}
.aside-menu .menu__nav .menu__item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  float: none;
  padding: 0;
}
.aside-menu .menu__nav > .menu__item > .menu__submenu .menu__subnav > .menu__item > .menu__link {
  padding: 0 10px 0 25px;
}
.aside-menu .menu__nav > .menu__item .menu__submenu .menu__item > .menu__link .menu__link-bullet {
  vertical-align: middle;
  text-align: left;
  flex: 0 0 15px;
  display: flex;
  align-items: center;
  line-height: 0;
}
.aside-menu
  .menu__nav
  > .menu__item
  .menu__submenu
  .menu__item
  > .menu__link
  .menu__link-bullet.menu__link-bullet--dot
  > span {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  vertical-align: middle;
  display: inline-block;
  background: #8592a6;
}
.aside-menu .menu__nav > .menu__item .menu__submenu .menu__item.sub1:hover > .menu__link {
  background: rgba(4, 84, 135, 0.1);
}
.aside-menu
  .menu__nav
  > .menu__item
  .menu__submenu
  .menu__item.sub1.menu__item--active
  > .menu__link {
  background: rgba(4, 84, 135, 0.2);
}
.aside-menu
  .menu__nav
  > .menu__item
  .menu__submenu
  .menu__item.sub1.menu__item--active
  > .menu__link
  .menu__link-text {
  color: #045487;
}
.aside-menu
  .menu__nav
  > .menu__item
  .menu__submenu
  .menu__item.sub1
  > .menu__link
  .menu__link-bullet.menu__link-bullet--dot
  > span {
  background: #045487;
}
.aside-menu .menu__nav > .menu__item .menu__submenu .menu__item.sub2:hover > .menu__link {
  background: rgba(207, 39, 135, 0.1);
}
.aside-menu
  .menu__nav
  > .menu__item
  .menu__submenu
  .menu__item.sub2.menu__item--active
  > .menu__link {
  background: rgba(207, 39, 135, 0.2);
}
.aside-menu
  .menu__nav
  > .menu__item
  .menu__submenu
  .menu__item.sub2.menu__item--active
  > .menu__link
  .menu__link-text {
  color: #cf2787;
}
.aside-menu
  .menu__nav
  > .menu__item
  .menu__submenu
  .menu__item.sub2
  > .menu__link
  .menu__link-bullet.menu__link-bullet--dot
  > span {
  background: #cf2787;
}

.kt-container {
  padding: 10px 30px 0 10px;
}
.kt-portlet {
  display: flex;
  /*flex-grow: 1;*/
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 5px;
}
.kt-portlet .kt-portlet__head,
.kt-portlet .kt-portlet__foot {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  padding: 0 25px;
  min-height: 80px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-label {
  display: flex;
  align-items: center;
  align-content: flex-start;
}
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar,
.kt-portlet .kt-portlet__foot .kt-portlet__toolbar {
  display: flex;
  align-items: center;
  align-content: flex-end;
}
.kt-portlet.flex-grow-1 .kt-portlet__body {
  flex-grow: 1;
}
.kt-portlet .kt-portlet__body {
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
  border-radius: 5px;
}
.kt-portlet .kt-portlet__foot {
  border-radius: 0 0 10px 10px;
}

// label {
//   color: #8592a6;
//   padding-left: 15px;
// }

.parted > .col {
  padding: 40px 15px 40px 40px;
}
.c-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.profile-form .first,
.profile-form .second {
  padding: 50px 40px 0;
}
.profile-form .profile-image {
  position: relative;
  width: 120px;
  height: 120px;
  margin: auto;
}
.profile-form .profile-image > img,
.profile-form .profile-image > .placeholder {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
}
.profile-form .profile-image > .placeholder {
  display: flex;
  background-color: #f8f8f8;
  color: #045487;
  justify-content: center;
  align-items: center;
}
.profile-form .profile-image > .btn {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  background-color: #cf2787;
  border-radius: 50%;
  padding: 5px;
  line-height: 0;
}
.profile-form .user-id {
  background-image: url('../../../../../../public/media/company-specific/pokupka/profile_bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 68px;
  color: #ffffff;
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-select.autocomplete {
  padding: 0;
}
.invalid-tooltip {
  z-index: 1;
}
.crs__menu {
  z-index: 2 !important;
}
.custom-select.autocomplete > div:first-of-type {
  height: 100%;
  border: none;
}
.custom-select.autocomplete > div:last-of-type {
  margin: 0;
}
.custom-control-input[type='checkbox'] ~ .custom-control-label::before,
.custom-control-input[type='checkbox']:not(:checked) ~ .custom-control-label::before,
.custom-control-input[type='checkbox'] ~ .custom-control-label::before,
.custom-control-input[type='checkbox']:not(:disabled) ~ .custom-control-label::before {
  border-radius: 5px;
}
.custom-control-input[type='radio'] ~ .custom-control-label::before,
.custom-control-input[type='radio']:not(:checked) ~ .custom-control-label::before,
.custom-control-input[type='radio'] ~ .custom-control-label::before,
.custom-control-input[type='radio']:not(:disabled) ~ .custom-control-label::before {
  border-radius: 10px;
}

.breadcrumb {
  display: none;
  font-size: 16px;
  line-height: 19px;
  color: #8592a6;
  background-color: transparent;
  margin: 0;
}

.notifications {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1005;
}
.notifications .toast {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  min-width: 250px;
}
.notifications .toast.text-white .toast-header,
.notifications .toast.text-white .toast-header .close {
  color: #fff;
}
.notifications .toast .toast-header {
  background-color: rgba(255, 255, 255, 0.2);
}
.notifications .toast .toast-header,
.notifications .toast .toast-body {
  border-left: 5px solid rgba(0, 0, 0, 0.2);
}

.table.table-special {
  border-collapse: separate;
  font-size: 14px;
  line-height: 16px;
  border-spacing: 0 10px;
}
.table.table-special > thead > tr > th {
  border: none;
  padding: 4px 20px;
  font-weight: normal;
  color: #8592a6;
}
.table.table-special > tbody > tr > td:first-child {
  border-left: 1px solid #c6c5d3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.table.table-special > tbody > tr > td:last-child {
  border-right: 1px solid #c6c5d3;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.table.table-special > tbody > tr > td {
  border-top: 1px solid #c6c5d3;
  border-bottom: 1px solid #c6c5d3;
  padding: 4px 20px;
  height: 50px;
  vertical-align: middle;
}
.table.table-special > tbody > tr > td + td {
  border-left: 1px solid transparent !important;
  position: relative;
  overflow: hidden;
}
.table.table-special > tbody > tr > td + td:before {
  position: absolute;
  top: 10px;
  left: 0;
  content: '';
  height: 100%;
  width: 0;
  border-left: 1px solid #c6c5d3;
}
.table.table-special > tbody > tr > td + td:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  height: 10px;
  width: 0;
  border-left: 1px solid #fff;
}
.table.table-special tbody td .btn-link.btn-sm {
  padding-right: 0;
  padding-left: 0;
}
.table.table-orders > tbody > tr.buyout > td:first-child {
  border-left-width: 5px;
}
.table.table-orders > tbody > tr.buyout > td,
.table.table-special > tbody > tr.details.buyout > td:before,
.table.table-special > tbody > tr.details.buyout > td:after {
  border-color: #045487;
}
.table.table-orders > tbody > tr.virtualaddress > td:first-child {
  border-left-width: 5px;
}
.table.table-orders > tbody > tr.virtualaddress > td,
.table.table-special > tbody > tr.details.virtualaddress > td:before,
.table.table-special > tbody > tr.details.virtualaddress > td:after {
  border-color: #cf2787;
}
.table.table-special > tbody > tr:not(.details).active > td:first-child {
  border-bottom-left-radius: 0;
}
.table.table-special > tbody > tr:not(.details).active > td:last-child {
  border-bottom-right-radius: 0;
}
.table.table-special > tbody > tr:not(.details).active > td {
  border-bottom: none;
}
.table.table-special > tbody > tr.details {
  display: none;
}
.table.table-special > tbody > tr.details.active {
  display: table-row;
}
.table.table-special > tbody > tr.details.buyout > td:before,
.table.table-special > tbody > tr.details.buyout > td:after {
}
.table.table-special > tbody > tr.details > td:before,
.table.table-special > tbody > tr.details > td:after {
  position: absolute;
  top: -10px;
  left: -5px;
  content: '';
  height: 10px;
  border-style: solid;
  border-width: 0;
  border-color: #c6c5d3;
}
.table.table-special > tbody > tr.details > td:before {
  border-left-width: 5px;
}
.table.table-special > tbody > tr.details > td:after {
  left: auto;
  right: -1px;
  border-right-width: 1px;
}
.table.table-special > tbody > tr.details > td .separator {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  height: 1px;
  width: 100%;
  background: $grey-light;
}
.table.table-special > tbody > tr.details > td {
  border-top: none;
  position: relative;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.underline {
  text-decoration: underline !important;
}
.text-gray-500 {
  color: #8592a6 !important;
}
.text-gray {
  color: #c6c5d3 !important;
}
.text-blue {
  color: #045487 !important;
}
.btn-link.text-gray:hover {
  color: #858492;
}
.modal-content {
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.modal-header {
  margin: 10px 30px 0;
  padding: 1rem 0;
  border-bottom-color: #c6c5d3;
}
.modal-title {
  color: #045487;
  font-size: 18px;
  line-height: 21px;
}
.modal-body {
  margin: 0 30px;
  padding: 1rem 0;
}
.modal-footer {
  border: none;
  padding: 0.75rem 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
}
.modal-footer,
.modal-footer .btn-link {
  color: #045487;
}

.modal-footer .btn-link svg.fa-flip-both {
  -webkit-transform: scale(-1) rotate(90deg);
  transform: scale(-1) rotate(90deg);
}

.help-item {
  display: flex;
  flex-direction: column;
  width: 246px;
  height: 244px;
  margin: 6px;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 18px;
  line-height: 21px;
  color: #8592a6;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
}
.help-item > .icon {
  width: 42px;
  height: 42px;
  margin-bottom: 10px;
}
.help-item > .icon.country {
  border: 2px solid #8592a6;
  border-radius: 50%;
  padding: 13px;
}
.help-item > .icon svg {
  width: 100%;
  height: 100%;
  fill: #8592a6;
}
.help-item > .icon.country svg {
  width: 40px;
  height: 40px;
  fill: none;
}
.help-item:hover {
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  text-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  background: url('../../../../../../public/media/company-specific/pokupka/storage.svg'),
    linear-gradient(92.92deg, #27ae60 2.67%, #f7c300 47.56%, #ce2586 97.7%);
}
.help-item:hover > .icon:not(.country) {
  width: 65px;
  height: 65px;
}
.help-item:hover > .icon.country {
  border-color: #ffffff;
}
.help-item:hover > .icon svg {
  fill: #ffffff;
  -webkit-filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.7));
}
.help-item:hover > .icon.country svg {
  fill: none;
}
.storage-modal table td:first-child {
  color: #8592a6;
  padding-left: 0;
}
.storage-modal table td:last-child {
  padding-right: 0;
}
.storages .btn-group {
  background: #f8f8f8;
  border-radius: 40px;
}
.storages .btn-group > .btn {
  padding-left: 12px;
  opacity: 0.6;
}
.storages .btn-group > .btn-clean {
  opacity: 1;
}
.storages .icon.country {
  display: inline-flex;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0 4px;
  border: 2px solid #8592a6;
  background: none;
  border-radius: 50%;
}
.storages .btn:hover > .icon.country,
.storages .btn-clean > .icon.country,
.storages .dropdown-item > .icon.country {
  margin-right: 5px;
}
.storages .btn-group > .btn > span {
  width: 0;
  display: inline-flex;
  overflow: hidden;
  transition: width 1s;
}
.storages .btn-group > .btn:hover > span,
.storages .btn-group > .btn.btn-clean > span {
  width: auto;
}
.storages .icon.country svg {
  width: 18px;
  height: 18px;
}
.storages .dropdown-toggle {
  position: relative;
}
.storages .dropdown-toggle {
  position: relative;
}
.storages .dropdown-toggle:after {
  position: absolute;
  right: 20px;
  top: 49%;
}
.storages .dropdown-item:hover {
  background-color: #f8f8f8;
  color: inherit;
}
.storages .dropdown-item.active,
.storages .dropdown-item:active {
  background-color: #e9ecef;
  color: inherit;
}
.storages td:last-child svg {
  display: none;
  color: #8592a6;
}
.storages td:last-child:hover svg {
  display: inline-block;
}

.react-calendar {
  width: 310px;
  border: none;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0;
  height: 4em;
}
.react-calendar__tile {
  height: 40px;
  font-size: 16px;
  line-height: 24px;
  color: #8592a6;
  padding: 0;
  position: relative;
  z-index: 1;
}
.react-calendar__tile abbr {
  z-index: 1;
}
.react-calendar__tile--hasActive,
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: rgba(207, 39, 135, 0.1);
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgba(207, 39, 135, 0.1);
  color: rgba(0, 0, 0, 0.87);
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  overflow: visible;
  color: #fff !important;
}
.react-calendar__tile--rangeStart:after,
.react-calendar__tile--rangeEnd:after {
  content: ' ';
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  height: 90%;
  background: #045487;
  border-radius: 50%;
  z-index: -1;
}
.react-calendar__tile--rangeStart {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.react-calendar__tile--rangeEnd {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.kt-portlet__head-label .btn-row {
  box-shadow: none;
  background: #f8f8f8;
}
.kt-portlet__head-label .btn-row .btn {
  font-size: 14px;
  line-height: 16px;
  padding: 12px 16px;
  color: #8592a6;
  border-radius: 30px;
  background: #f8f8f8;
}
.kt-portlet__head-label .btn-row .btn.active {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  background: #ffffff;
  z-index: 1;
}

.buyout-form,
.track-form {
  padding: 15px;
  border: 1px solid #045487;
  border-radius: 5px;
  margin-top: 15px;
  position: relative;
}
.track-form {
  border-color: #cf2787;
}
.track-form .remove {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.buyout-form .product-image {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 160px;
  max-height: 200px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
.buyout-form .product-image > img,
.buyout-form .product-image > .placeholder {
  /*width: 100%;*/
  height: auto;
  max-height: 210px;
}
.buyout-form .product-image > .placeholder {
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #f8f8f8;
  color: #045487;
  justify-content: center;
  align-items: center;
}
.buyout-form .product-image > .btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #fff;
  background-color: #cf2787;
  border-radius: 50%;
  padding: 5px;
  margin: 0;
  line-height: 0;
}

.track-form .shop-logo > .placeholder {
  display: flex;
  width: 50px;
  height: 100%;
  background-color: #f8f8f8;
  color: #045487;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
.btn-gray {
  background-color: #f8f8f8;
  color: #000;
  border: 0;
}
.btn-gray:hover,
.btn-gray:active {
  background-color: #f8f8f8;
  color: #000;
}

.contacts {
  background-image: url('./images/img_contacts.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: auto;
  padding-bottom: 200px !important;
}
.contacts .btn-gray {
  min-width: 220px;
  height: 50px;
}

.faq {
  padding: 0 !important;
}
.faq .accordion .card {
  border-bottom: 1px solid #c6c5d3;
  border-radius: 5px;
}
.faq .accordion .card .card-header {
  position: relative;
  padding-right: 40px;
}
.faq .accordion .card svg {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.faq .btn-gray {
  display: block;
  width: 100%;
  text-align: left;
  padding: 20px 25px;
  border-radius: 5px;
  border-left: 5px solid #f8f8f8;
}
.faq .btn-gray.active {
  background: #f6ebf4;
  border-left-color: #ce2586;
}

.products .row > div {
  display: flex;
  align-items: center;
}
.products .row > div:not(:first-child) {
  justify-content: center;
}

.rejected {
  background-color: lightpink;
}

.alert {
  display: flex;
  align-items: stretch;
  padding: 1rem 2rem;
  margin: 0 0 20px 0;
  border-radius: 4px;
}
.alert-elevate {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}
.alert .alert-icon.alert-icon-top {
  align-items: flex-start;
  align-content: stretch;
}
.alert .alert-icon {
  display: flex;
  align-items: center;
  padding: 0 1.25rem 0 0;
}
.alert .alert-text {
  align-self: center;
  flex-grow: 1;
}

.details {
  color: #8592a6;
}
.details .btn.text-link {
  color: #045487;
  text-decoration-line: underline;
  font-size: 12px;
  line-height: 14px;
}
.details .timeline .item {
  display: block;
  width: 20%;
  height: 1px;
  background-image: none;
  background-color: #c6c5d3;
  float: left;
  position: relative;
}
.details .timeline .item.active {
  background-color: transparent;
  background-image: linear-gradient(to right, #333 40%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 15px 1px;
  background-repeat: repeat-x;
}
.details .timeline .item:before,
.details .timeline .item:last-child:after {
  position: absolute;
  left: 0;
  top: -4px;
  content: '';
  width: 9px;
  height: 9px;
  border: 1px solid #c6c5d3;
  border-radius: 50%;
  background: #fff;
}
.details .timeline .item.active:before {
  border: 1px solid #045487;
}
.details .timeline .item:last-child:after {
  right: -4px;
  left: unset;
}
.details .timeline .item.active:last-child:after {
  border: 1px solid #cf2787;
}
.details .actions {
  margin-bottom: 0;
}
.details .actions td {
  padding-top: 6px;
  padding-bottom: 6px;
}
.details .actions td + td {
  border-left: 1px solid #c6c5d3;
  position: relative;
}
.details .actions td + td:before,
.details .actions td + td:after {
  position: absolute;
  left: -1px;
  content: '';
  width: 1px;
  height: 6px;
  background: #fff;
}
.details .actions td + td:before {
  top: 0;
}
.details .actions td + td:after {
  bottom: 0;
}

.finance_total_label {
  color: #8592a6;
}
.finance_total {
  color: #045487;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.no-action {
  pointer-events: none;
}

#not-page .scrollbar-container .ps__rail-y {
  opacity: 0.6;
}
#notifications.card-columns {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
}
#notifications .card {
  cursor: pointer;
}
#notifications .card.active {
  border-left: 1px solid #ce2586;
  background: none;
  background: linear-gradient(90deg, #ce2586 4px, transparent 4px);
}
#notification-data .text {
  color: #8592a6;
  white-space: pre-wrap;
}

.gnp > .nav-item {
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  z-index: 1;
}
.gnp > .nav-item.active {
  background: #cf2787;
  color: #fff;
  z-index: 2;
}
.finance_hiding_slide {
  padding: 20px 25px 6px;
}

.fs-15 {
  font-size: 15px;
}
@media (max-width: 1400px) {
  .aside-menu .menu__nav .menu__item:not(.menu__item--open) + .menu__item {
    margin-top: 14px;
  }
  .profile-form .first,
  .profile-form .second {
    padding: 40px 40px 0;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  .kt-portlet .kt-portlet__head,
  .kt-portlet .kt-portlet__foot {
    min-height: 60px;
  }
  .btn:not(.btn-link) {
    display: inline-flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
    font-size: 14px;
    line-height: 16px;
  }
  .btn.btn-clean > .icon > svg {
    vertical-align: -0.7em;
  }
}
@media (max-width: 767px) {
  .header {
    display: none;
  }
  .header-mobile {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    height: 50px;
    min-height: 50px;
    position: relative;
    z-index: 1002;
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  .header-mobile.header-mobile--fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
  .header-mobile--fixed .wrapper {
    padding-top: 50px;
  }
  .header-mobile__logo {
    /*box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);*/
  }
  .header-mobile__logo,
  .header-mobile__toggler {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .header-mobile__logo img {
    max-height: 34px;
  }
  .kt-container {
    padding: 0;
    background-color: #fff;
  }
  .aside.aside--on {
    transition: left 0.3s ease, right 0.3s ease;
    left: 0;
  }
  .aside {
    z-index: 1001;
    position: fixed;
    -webkit-overflow-scrolling: touch;
    top: 50px;
    bottom: 0;
    overflow-y: auto;
    -webkit-transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: left 0.3s ease, right 0.3s ease;
    left: -245px;
  }
  .aside-overlay {
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.1);
    animation: animate-fade-in 0.3s linear 1;
  }
  .breadcrumb {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header__topbar {
    margin-left: 1rem;
  }
  .the_add {
    left: auto;
    top: auto;
    bottom: 15px;
    right: 15px;
  }
  .fab .btn-normal {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-image: url('../../../../../../public/media/company-specific/pokupka/btn_small.svg');
  }
  .fab .dropdown-item,
  .the_add .dropdown-item {
    font-size: 14px;
    line-height: 16px;
  }
  .fab .dropdown-item:first-child,
  .the_add .dropdown-item:first-child {
    color: #045487;
  }
  .fab .dropdown-item:last-child,
  .the_add .dropdown-item:last-child {
    color: #cf2787;
  }
  .parted > .col {
    padding: 15px;
    box-shadow: none;
  }
  .profile-form .first {
    padding: 30px 15px 0 15px;
    box-shadow: none;
  }
  .profile-form .second {
    padding: 0 15px 10px;
  }
  .breadcrumb {
    display: block;
  }
  .kt-portlet .kt-portlet__head,
  .kt-portlet .kt-portlet__foot {
    padding: 15px;
    min-height: 70px;
  }
  .kt-portlet .kt-portlet__body {
    padding: 5px 10px;
  }
  .modal-header {
    margin: 10px 10px 0;
  }
  .modal-body {
    margin: 0 10px;
  }
  .modal-footer {
    padding: 0.75rem 10px;
  }
  .table.table-special.table-orders > tbody > tr > td + td:before,
  .table.table-special.table-orders > tbody > tr > td + td:after {
    display: none;
  }
  .table.table-special.single-col > tbody > tr > td:first-child {
    border-right: 1px solid #c6c5d3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .table.table-special.single-col > tbody > tr.details {
    display: none;
  }
  .table.table-special.single-col > tbody > tr.active > td {
    border-bottom: 1px solid #c6c5d3;
    border-bottom-left-radius: 5px !important;
  }
  .table.table-finance-sm > thead > tr > th {
    height: 0;
    padding: 0;
  }
  .table.table-finance-sm > tbody > tr > td {
    padding: 0.5rem;
  }
  .table.table-finance-sm > tbody > tr > td:first-child {
    color: #8592a6;
  }
  .table.table-finance-sm > tbody > tr:last-child > td:first-child {
    text-align: right;
  }
  .table.table-finance-sm > tbody > tr:last-child > td:last-child {
    font-size: 18px;
    line-height: 21px;
  }
  .table.table-finance-sm > tbody > tr > td:last-child {
    color: #000000;
    text-align: right;
    white-space: nowrap;
  }
  .xs-small {
    font-size: 80%;
    font-weight: 400;
  }
  .kt-portlet__head-label .btn-row {
    background: none;
  }
  .bt-xs {
    border-top: 1px solid #c6c5d3;
  }
  .finance_total {
    font-size: 30px;
    line-height: 35px;
  }
  .fs-3 {
    font-size: 18px;
    line-height: 21px;
  }
  .storages table {
    width: 100% !important;
  }
  .storages tr,
  .storages td {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .storages tr {
    padding: 4px 0;
  }
  .storages td:first-child {
    font-size: 14px;
    color: #6c757d;
  }
  .storages td:last-child div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .storages td:last-child svg {
    display: inline-block;
    width: 18px;
    height: 18px;
  }
  .contacts {
    background-size: contain;
  }
  .bg-xs-transparent {
    background: transparent;
  }
  .shadow-xs-none {
    box-shadow: none;
  }
}
@media (min-width: 768px) {
  .header-mobile {
    display: none;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 25px;
    height: 80px;
    min-height: 80px;
    position: relative;
    z-index: 1002;
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  .aside--fixed .aside {
    position: fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .aside--fixed.aside--minimize .wrapper {
    padding-left: 56px;
  }
  .header.header--fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
  .header--fixed .wrapper {
    padding-top: 80px;
  }
  .aside--fixed .wrapper {
    padding-left: 225px;
  }
  .aside--minimize .aside-tools {
    flex-direction: column-reverse !important;
  }
  .aside--minimize .menu__link-text {
    display: none !important;
  }
  .aside--minimize .aside-tools {
    position: relative;
  }
  .aside--minimize .the_add .btn-normal {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-image: url('../../../../../../public/media/company-specific/pokupka/btn_small.svg');
  }
  .fab .dropdown-item,
  .the_add .dropdown-item {
    position: relative;
    color: #8592a6;
    font-size: 16px;
    line-height: 19px;
    padding-left: 35px;
  }
  .fab .dropdown-item:before,
  .the_add .dropdown-item:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 45%;
    left: 22px;
    border: 0.17rem solid;
    border-radius: 50%;
  }
  .fab .dropdown-item:first-child:before,
  .the_add .dropdown-item:first-child:before {
    border-color: #045487;
  }
  .fab .dropdown-item:last-child:before,
  .the_add .dropdown-item:last-child:before {
    border-color: #cf2787;
  }
  .profile-form .user-id {
    background-size: auto;
    background-position: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-top-right-radius: 5px;
    margin: 0;
    padding: 0 40px 16px 0;
  }
  .md-underline {
    text-decoration: underline !important;
  }
  .btn-row {
    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }

  .table.table-special .details td .row:not(.no-borders) > .col:not(.d-none) + .col:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: 100%;
    width: 0;
    border-left: 1px solid #c6c5d3;
  }
  .table.table-special .details td .row:not(.no-borders) > .col:not(.d-none) + .col:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    height: 5px;
    width: 0;
    border-left: 1px solid #fff;
  }
  .table.table-orders .product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .storage-modal {
    max-width: 650px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  // .the_add {
  //   position: fixed;
  //   top: 94px;
  //   left: 8px;
  // }
  .aside-tools {
    padding-top: 60px !important;
  }
  .kt-container {
    padding: 10px 10px 20px 10px;
  }
  .parted > .col {
    padding: 20px;
  }
  .profile-form .first {
    padding: 40px 20px 10px 20px;
  }
  .profile-form .second {
    padding: 20px 20px 10px;
  }
  .profile-form .user-id {
    min-height: 109px;
  }
}

@media (max-width: 1199px) {
  .track-form .border-left {
    border: none !important;
  }
  .track-form .border-left hr {
    border-color: #c6c5d3;
    border-bottom: none;
  }
}
@media (min-width: 1199px) and (max-width: 1400px) {
  // label {
  //   padding-left: 10px;
  // }
}
@media (min-width: 1200px) {
  #main {
    height: 100vh;
  }
  .the_add {
    top: 94px;
    left: 60px;
  }
  .aside--minimize .the_add {
    top: 94px;
    left: 70px;
  }
  .profile-form .user-id {
    min-height: 130px;
    background-size: cover;
  }
  .pr-xl-30 {
    padding-right: 30px;
  }
  .parted > .col .scrollbar-container {
    padding-right: 25px;
  }
}
@media (min-width: 1400px) {
  .d-lgx-none {
    display: none !important;
  }
  .d-lgx-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
}
