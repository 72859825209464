.app-header {
  box-shadow: $pokupka-shadow;
  z-index: 110 !important;
}
@include media-breakpoint-down(md) {
  .app-header .app-container {
    padding-left: 0 !important;
  }
  .header-logo-minimized {
    padding: 17px;
    box-shadow: $pokupka-shadow;
  }
}
@include media-breakpoint-up(md) {
  .app-header {
    position: fixed;
    width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  [data-kt-app-header-fixed='true'][data-kt-app-sidebar-fixed='true'][data-kt-app-sidebar-push-header='true']
    .app-header,
  [data-kt-app-header-fixed='true'][data-kt-app-sidebar-sticky='on'][data-kt-app-sidebar-push-header='true']
    .app-header {
    left: 0;
  }
}
