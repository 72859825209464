//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// company mockup colors
:root {
  --frame1: rgb(162, 56, 141);
  --frame2: rgb(206, 37, 134);
  --frame3: rgb(239, 126, 92);
  --frame4: rgb(251, 210, 32);
  --frame5: rgb(201, 203, 39);
  --frame6: rgb(136, 192, 96);
  --frame7: rgb(3, 134, 135);
  --frame8: rgb(8, 122, 123);
  --frame9: rgb(40, 108, 180);
  --frame10: rgb(64, 61, 144);
  --additional: rgb(4, 84, 135);
  --accent: rgb(207, 39, 135);

  --frame1-hover: rgba(162, 56, 141, 0.1);
  --frame2-hover: rgba(206, 37, 134, 0.1);
  --frame3-hover: rgba(239, 126, 92, 0.1);
  --frame4-hover: rgba(251, 210, 32, 0.1);
  --frame5-hover: rgba(201, 203, 39, 0.1);
  --frame6-hover: rgba(136, 192, 96, 0.1);
  --frame7-hover: rgba(3, 134, 135, 0.1);
  --frame8-hover: rgba(8, 122, 123, 0.1);
  --frame9-hover: rgba(40, 108, 180, 0.1);
  --frame10-hover: rgba(64, 61, 144, 0.1);
  --additional-hover: rgba(4, 84, 135, 0.1);
  --accent-hover: rgba(207, 39, 135, 0.1);

  --frame1-active: rgba(162, 56, 141, 0.2);
  --frame2-active: rgba(206, 37, 134, 0.2);
  --frame3-active: rgba(239, 126, 92, 0.2);
  --frame4-active: rgba(251, 210, 32, 0.2);
  --frame5-active: rgba(201, 203, 39, 0.2);
  --frame6-active: rgba(136, 192, 96, 0.2);
  --frame7-active: rgba(3, 134, 135, 0.2);
  --frame8-active: rgba(8, 122, 123, 0.2);
  --frame9-active: rgba(40, 108, 180, 0.2);
  --frame10-active: rgba(64, 61, 144, 0.2);
  --additional-active: rgba(4, 84, 135, 0.2);
  --accent-active: rgba(207, 39, 135, 0.2);

  // redefining
  --kt-form-check-input-checked-bg-color: white !important;
  --kt-form-check-input-checked-border-color: #c6c5d3 !important;
}

// Theme colors
// Primary
$primary: #009ef7;
$primary-active: #0095e8;
$primary-light: #f1faff;
$primary-light-dark: #212e48;
$primary-inverse: #cf2787;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;

// custom
$active: #009432;
$blocked: rgb(194, 0, 0);
$on-hold: rgb(151, 151, 0);
$activeBg: rgba(0, 148, 50, 0.1);
$blockedBg: rgba(194, 0, 0, 0.1);
$on-holdBg: rgba(255, 255, 0, 0.1);
$pokupka-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
$additional: #045487;
$dark: #000000;
$cont-bg: #f8f8f8;
$grey-text: #8592a6;
$grey-light: #c6c5d3; // for icons
$accent: #cf2787;
$white: #ffffff;

$border-radius: 5px;
$border-color: $grey-light;

$shadow-map: (
  'pokupka': $pokupka-shadow,
);

@each $name, $value in $shadow-map {
  .shadow-#{$name} {
    box-shadow: $value;
  }
  .shadow-#{$name}-inverted {
    box-shadow: $value inset;
  }
}

$basic-colors-map: (
  'additional': $additional,
  'dark': $dark,
  'container': $cont-bg,
  'grey': $grey-text,
  'grey-light': $grey-light,
  'accent': $accent,
  'white': $white,
  'none': transparent,
);

[data-kt-app-layout='dark-sidebar']
  .app-sidebar
  .menu
  .menu-item
  .menu-link:not(.active)
  .menu-title {
  color: #8592a6;
}

$company-specific-colors-active: (
  'frame1': var(--frame1-active),
  'frame2': var(--frame2-active),
  'frame3': var(--frame3-active),
  'frame4': var(--frame4-active),
  'frame5': var(--frame5-active),
  'frame6': var(--frame6-active),
  'frame7': var(--frame7-active),
  'frame8': var(--frame8-active),
  'frame9': var(--frame9-active),
  'frame10': var(--frame10-active),
  'additional': var(--additional-active),
  'accent': var(--accent-active),
);

$company-specific-colors-hover: (
  'frame1': var(--frame1-hover),
  'frame2': var(--frame2-hover),
  'frame3': var(--frame3-hover),
  'frame4': var(--frame4-hover),
  'frame5': var(--frame5-hover),
  'frame6': var(--frame6-hover),
  'frame7': var(--frame7-hover),
  'frame8': var(--frame8-hover),
  'frame9': var(--frame9-hover),
  'frame10': var(--frame10-hover),
  'additional': var(--additional-hover),
  'accent': var(--accent-hover),
);

@each $name, $value in $company-specific-colors-active {
  .bg-#{$name}-active {
    background-color: var(--#{$name}-active) !important;
    & *:not(.menu-arrow) {
      color: var(--#{$name}) !important;
    }
  }
}

@each $name, $value in $company-specific-colors-hover {
  .bg-#{$name}-hover:hover {
    background-color: var(--#{$name}-hover) !important;
    & * {
      color: var(--#{$name}) !important;
    }
  }
}

@each $name, $value in $basic-colors-map {
  .text-custom-#{$name} {
    color: $value !important;
  }

  .text-hover-#{$name}:hover {
    color: $value !important;
  }
  .bg-custom-#{$name} {
    background-color: $value !important;
  }

  .border-color-#{$name} {
    border-color: $value !important;
  }

  .svg-icon-custom-#{$name} {
    color: $value !important;
    fill: $value !important;
  }
  .svg-icon-custom-hover-#{$name} {
    &:hover {
      color: $value !important;
      fill: $value !important;
    }
  }
}

$border-radiuses-map: (
  'unset': 0px,
  'def': $border-radius,
);

@each $name, $value in $border-radiuses-map {
  .border-radius-#{$name} {
    border-radius: $value !important;
  }

  .border-radius-left-#{$name} {
    border-top-left-radius: $value !important;
    border-bottom-left-radius: $value !important;
  }
  .border-radius-right-#{$name} {
    border-top-right-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }

  .border.border-radius-left-bottom-#{$name} {
    border-bottom-left-radius: $value !important;
  }

  .border.border-radius-left-top-#{$name} {
    border-top-left-radius: $value !important;
  }

  .border.border-radius-right-bottom-#{$name} {
    border-bottom-right-radius: $value !important;
  }

  .border.border-radius-right-top-#{$name} {
    border-top-right-radius: $value !important;
  }
}
