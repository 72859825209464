.app-sidebar .menu-item {
  margin-left: 0 !important;
  padding: 0;
}
.app-sidebar .menu-item:not(:last-child):not(.show) {
  margin-bottom: 20px;
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar .app-sidebar-logo {
  border: none;
}
.app-sidebar {
  margin-top: var(--kt-app-header-height) !important;
}

.app-sidebar .app-container {
  box-shadow: $pokupka-shadow;
  padding: 0 !important;
}

.app-sidebar .menu-title {
  font-size: 16px;
}

$app-sidebar-base-width: 225px !default;
$app-sidebar-base-width-tablet: 218px !default;
$app-sidebar-base-width-mobile: 240px !default;
$app-sidebar-header-height: 60px;

:root {
  --kt-app-sidebar-width: #{$app-sidebar-base-width-mobile} !important;
  --kt-app-sidebar-minimized-width: 48px !important;
  --kt-app-header-height: 70px !important;
  --kt-app-sidebar-header-height: #{$app-sidebar-header-height} !important;
}
[data-kt-app-sidebar-hoverable='true'] .app-sidebar .app-sidebar-wrapper,
.app-sidebar {
  width: var(--kt-app-sidebar-width) !important;
}

[data-kt-app-layout='dark-sidebar'] .app-sidebar .menu .menu-item > .menu-link .menu-arrow:after {
  background-color: #787887 !important;
}

.app-sidebar.hover-scroll-overlay-y::-webkit-scrollbar {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
}

.app-sidebar.hover-scroll-overlay-y::-webkit-scrollbar {
  display: none; // rest of browsers
}

.app-sidebar-header {
  height: var(--kt-app-sidebar-header-height) !important;
}

.sidebar-socials {
  display: none;
}
@include media-breakpoint-down(md) {
  .app-sidebar.hover-scroll-overlay-y {
    position: absolute;
  }

  .app-sidebar-logo {
    display: block !important;
  }
}

@include media-breakpoint-up(sm) {
  :root {
    --kt-app-sidebar-width: #{$app-sidebar-base-width-tablet} !important;
    --kt-app-header-height: 100px !important;
    --kt-app-sidebar-header-height: 70px !important;
  }
  [data-kt-app-sidebar-hoverable='true'] .app-sidebar .app-sidebar-wrapper,
  .app-sidebar,
  .header-logo-minimized {
    width: var(--kt-app-sidebar-width) !important;
  }

  .app-sidebar-logo {
    justify-content: center !important;
  }
  .app-sidebar {
    width: var(--kt-app-sidebar-width) !important;
    overflow-x: hidden !important;
  }

  .sidebar-socials {
    display: flex;
    justify-content: center;
  }

  [data-kt-app-sidebar-minimize='on'] .sidebar-socials {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  [data-kt-app-sidebar-minimize='on'] .app-sidebar .app-sidebar-wrapper,
  [data-kt-app-sidebar-minimize='on'] .app-sidebar {
    width: var(--kt-app-sidebar-minimized-width) !important;
    overflow-x: hidden !important;
  }

  [data-kt-app-sidebar-minimize='on'] #kt_app_sidebar_logo {
    overflow: hidden;
  }
  .app-sidebar-toggle {
    margin-right: 13px;
  }
  [data-kt-app-sidebar-minimize='on'] .app-sidebar-toggle {
    margin-right: 0;
  }

  [data-kt-app-sidebar-minimize='on'] .app-wrapper {
    margin-left: var(--kt-app-sidebar-minimized-width) !important;
  }
  [data-kt-app-sidebar-minimize='on'] .app-sidebar:hover {
    // transition: none !important;
    width: var(--kt-app-sidebar-minimized-width) !important;
  }
  .menu-link > .svg-icon > svg {
    margin-right: 13px;
  }

  [data-kt-app-sidebar-minimize='on'] .menu-link .menu-title,
  [data-kt-app-sidebar-minimize='on'] .menu-link .menu-arrow {
    display: none !important;
  }
}

@include media-breakpoint-only(md) {
  :root {
    --kt-app-sidebar-minimized-width: 65px !important;
  }
  .app-sidebar-logo {
    display: flex !important;
  }

  .drawer.drawer-start {
    transform: translateX(0%);
  }

  [data-kt-app-sidebar-minimize='on'] .app-sidebar-header {
    height: 135px !important;
    text-align: center;

    & > div {
      height: 100%;
      flex-direction: column-reverse;
      justify-content: space-evenly;
      align-items: center;
      margin: 0 auto;
    }
  }
}

@include media-breakpoint-up(md) {
  :root {
    --kt-app-sidebar-width: #{$app-sidebar-base-width} !important;
  }

  [data-kt-app-sidebar-minimize='on'] .menu-link > .svg-icon > svg {
    margin-right: 3px !important;
  }
}

.app-header .app-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.app-sidebar-toggle-icon {
  width: 25px;
  height: 17px;
}

.hover-scroll-overlay-y {
  background-color: transparent;
}
