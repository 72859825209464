#kt_app_content {
  padding: 10px;
}
#kt_app_content_container.app-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-height: calc(100vh - var(--kt-app-footer-height) - var(--kt-app-header-height) - 50px);
  background-color: white;
  display: contents;
}

.card {
  border: none !important;
  box-shadow: $pokupka-shadow;

  &.list-card {
    box-shadow: none;
    height: 100%;
  }

  &.page-card {
    box-shadow: none;
  }
}
.card.list-card .card-header,
.card.list-card .card-footer {
  height: 70px;
}

.card.list-card .card-body {
  padding-bottom: 70px;
}

.list-card .card-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.card.list-card .card-scroll {
  position: static;
  overflow-y: auto;
  overflow-x: hidden;
}
.card-footer {
  position: static;
}

.create-order-height {
  height: calc(100vh - var(--kt-app-footer-height) - var(--kt-app-header-height) - 20px);
}

@include media-breakpoint-down(md) {
  #kt_app_content {
    padding: 0;
  }

  .card .card-body {
    padding: 35px 15px 0 15px;
  }

  .card .card-header {
    padding: 0;
  }
  .card > .card-header:not(.flex-nowrap) {
    padding: 0;
  }
}

// @include media-breakpoint-only(md) {
//   [data-kt-app-header-fixed='true'] .app-wrapper {
//     margin-top: 0 !important;
//   }
// }

@include media-breakpoint-up(md) {
  .card .card-body {
    padding: 0;
  }

  .card.list-card .card-header,
  .card.list-card .card-footer {
    height: 80px;
  }

  .card.list-card .card-body {
    padding-bottom: 80px;
  }

  .card-footer.position-absolute {
    bottom: 0;
    right: -1px;
    width: 100%;

    & .button-container {
      right: 15px;
    }
  }

  .profile-form .card-footer.position-absolute {
    bottom: 0;
    & .button-container {
      bottom: 15%;
    }
  }

  .card-footer button.position-absolute {
    right: 2%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@include media-breakpoint-up(lg) {
  #kt_app_content_container.app-container {
    box-shadow: $pokupka-shadow;
  }

  // .profile-form .card-footer.position-absolute {
  //   bottom: 29px;
  //   & .button-container {
  //     bottom: -15%;
  //   }
  // }
}
